import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import {
  BinarytreeApi,
  BlockUserApi,
  GetUsersListApi,
  SubScriptionReportApi,
  UnBlockuserApi,
  postRequest,
  signleUserBinaryDetailsApi,
  singleUserApi,
  toggleChatApi,
  syncOldMessages,
} from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { Translation, useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { loginuser } from "backendServices/ApiCalls";
import { Link as MyLink, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Grid,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import GroupIcon from "@mui/icons-material/Group";
import Div from "@jumbo/shared/Div";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import Modals from "./Modals";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Userlisttable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedUsername, setSelectedUsername] = useState("");
  const [open, setOpen] = React.useState(false);
  const [binaryDetailsOpen, setBinaryDetailsOpen] = React.useState(false);
  const [getUsersList, setGetUsersList] = useState([]);
  const [binaryDetails, setBinaryDetails] = useState(null);
  const [binaryLoader, setBinaryLoader] = useState(false);
  const [blockUser, setBlockUser] = useState("");
  const [dialoguserdata, setDialogUserData] = useState("");
  const [singleRowData, setSingleRowData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [currentPayoutDetails, setCurrentPayoutDetails] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [isChatEnable, setIsChatEnable] = useState(0);
  const [chatBtnDisable, setChatBtnDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  
  
  
  const [updatepayoutinformationopen, setUpdatePayoutInformationOpen] =
    useState(false);


  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const theme = useTheme();

  // GET LOGIN USER DETAILS
  const { loginUserData } = useContext(CustomProvider);

  const GetUsersList = () => {
    setIsLoading(true);
    GetUsersListApi(
      (response) => {
        const processedData = response?.data?.userdata.map((row, index) => ({
          ...row,
          index: index + 1,
        }));
        // console.log("processedData: ",processedData)
        setGetUsersList(processedData);
        setIsLoading(false);
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const loginClick = (params) => {
    let username = params?.row?.username;
    let data = {
      username: username,
    };
    loginuser(
      data,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          Cookies.set("user_login_by_admin", response?.data?.userid, {
            expires: 1,
          });
          window.open("https://app.novalya.com/", "_blank");
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };
  const DialogloginClick = () => {
    // let username = params?.row?.username;
    let data = {
      userId: dialoguserdata?.row?.id,
    };

    postRequest(
      "/createusersession",
      data,
      (response) => {
        if (response?.data?.status === "success") {
          window.open(response?.data?.accessurl, "_blank");
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };
  const BlockUserdata = () => {
   
    let data = {
      userid: dialoguserdata?.id,
    };
    

 dialoguserdata?.row?.login_status === "Unblock"? data["status"]="Block": data["status"]="Unblock"
      BlockUserApi(
        data,
        (response) => {
          if (response?.data?.status === "error") {
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: "error",
            });
          } else if (response?.data?.status === "success") {
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: "success",
            });
            setOpen(false);
            GetUsersList();
          } else {
            setalertData({
              show: true,
              message: "Something went wrong please try again later",
              variant: "error",
            });
          }
        },
        (error) => {
          console.log(error?.response?.data);
        }
      );
   
  };

  const handleBinaryDetailsClick = (data) => {
    setBinaryLoader(true);
    setBinaryDetailsOpen(true);
    let params = {
      userid: data.id,
    };
    signleUserBinaryDetailsApi(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          setSingleRowData(data);
          setBinaryDetails(response?.data?.data);
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
        }
        setBinaryLoader(false);
      },
      (error) => {
        setBinaryLoader(false);
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    GetUsersList();
    // BlockUserdata();
  }, []);
  // const rowsWithIndex = getUsersList?.map((row) => ({ ...row, id: row.id }));

  // const handleViewTree = () => {
  //     BinarytreeApi
  // }

  useEffect(() => {
   setIsChatEnable(dialoguserdata?.row?.isChatActive)
  }, [dialoguserdata])
  

  const toggleApi = ()=>{
    setChatBtnDisable(true);
    const data ={
      userid:dialoguserdata?.row?.id, 
      isChatActive:dialoguserdata?.row?.isChatActive?false:true
    }
    toggleChatApi(
      data,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
         
        } else if (response?.data?.status === "success") {
          setIsChatEnable(isChatEnable?false:true)
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          GetUsersList();
          setOpen(false)

        
        } else {
          setalertData({
            show: true,
            message: "Something went wrong. Please try again leter",
            variant: "error",
          });
        }
     setChatBtnDisable(false);

      },
      (error) => {
        setChatBtnDisable(false);
      }
    );
   }

  var columns = [];
  if (loginUserData.usertype === "reseller" || loginUserData.parent_id) {
    columns = [
      {
        field: "index",
        headerName: "#",
        width: 80,
      },

      {
        field: "username",
        headerName: "User Name",
        width: 200,
        editable: true,
        groupable: false,
        renderCell: (params) => {
          let value = params?.value;
          let originalString = value;
          if (originalString?.includes("‰")) {
            const replacedString = originalString?.replace(/‰/g, "¨");
            const decodedString = decodeURIComponent(escape(replacedString));
            value = decodedString;
          }
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setDialogUserData(params);
                setOpen(true);
              }}
            >
              {value?.includes("é") ||
                value?.includes("è") ||
                value?.includes("É") ||
                value?.includes("ë") ||
                value?.includes("ç") ||
                value?.includes("î") ||
                value?.includes(" ") ||
                value?.includes("ä") ||
                value?.includes("ü")
                ? value
                : decodeURIComponent(escape(value))}
            </div>
          );
        },
      },
      {
        field: "firstname",
        headerName: "First Name",
        width: 200,
        editable: true,
        renderCell: (params) => {
          let value = params?.value;
          let originalString = value;
          if (originalString?.includes("‰")) {
            const replacedString = originalString?.replace(/‰/g, "¨");
            const decodedString = decodeURIComponent(escape(replacedString));
            value = decodedString;
          }
          return (
            <div>
              {value?.includes("é") ||
                value?.includes("è") ||
                value?.includes("É") ||
                value?.includes("ë") ||
                value?.includes("ç") ||
                value?.includes("î") ||
                value?.includes(" ") ||
                value?.includes("ü") ||
                value?.includes("ö") ||
                value?.includes("ä") ||
                value?.includes("Ë")
                ? value
                : decodeURIComponent(escape(value))}
            </div>
          );
        },
      },

      {
        field: "lastname",
        headerName: "Last Name",
        width: 200,
        editable: true,
        groupable: false,
        renderCell: (params) => {
          let value = params?.value;
          let originalString = value;
          if (originalString?.includes("‰")) {
            const replacedString = originalString?.replace(/‰/g, "¨");
            const decodedString = decodeURIComponent(escape(replacedString));
            value = decodedString;
          }
          return (
            <div>
              {value?.includes("é") ||
                value?.includes("è") ||
                value?.includes("É") ||
                value?.includes("ë") ||
                value?.includes("ç") ||
                value?.includes("î") ||
                value?.includes(" ") ||
                value?.includes("ü") ||
                value?.includes("ö") ||
                value?.includes("ä") ||
                value?.includes("Ë")
                ? value
                : decodeURIComponent(escape(value))}
            </div>
          );
        },
      },
      {
        field: "email",
        headerName: "Email",
        width: 200,
        editable: true,
        groupable: false,
      },
      {
        field: "createdat",
        headerName: "CreatedAt",
        width: 200,
        editable: true,
        groupable: false,
      },
      {
        field: "mobile",
        headerName: "Mobile ",
        width: 200,
        editable: true,
        groupable: false,
      },
      {
        field: "subscription_status",
        headerName: "Subscription Status",
        width: 200,
      },
      {
        field: "login_status",
        headerName: " Login Status",
        width: 200,
      },
    ];
  } else {
    columns = [
      {
        field: "index",
        headerName: "#",
        width: 80,
      },
      {
        field: "username",
        headerName: "User Name",
        width: 200,
        editable: true,
        groupable: false,
        renderCell: (params) => {
          let value = params?.value;
          let originalString = value;
          if (originalString?.includes("‰")) {
            const replacedString = originalString?.replace(/‰/g, "¨");
            const decodedString = decodeURIComponent(escape(replacedString));
            value = decodedString;
          }
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setDialogUserData(params);
                setOpen(true);
              }}
            >
              {value?.includes("é") ||
                value?.includes("è") ||
                value?.includes("É") ||
                value?.includes("ë") ||
                value?.includes("ç") ||
                value?.includes("î") ||
                value?.includes(" ") ||
                value?.includes("ä") ||
                value?.includes("ü")
                ? value
                : decodeURIComponent(escape(value))}
            </div>
          );
        },
      },
      {
        field: "randomcode",
        headerName: "Affiliate Code",
        width: 200,
        editable: true,
        groupable: false,
        renderCell: (params) => {
          let value = params?.value;
          return <div>{value}</div>;
        },
      },
      {
        field: "firstname",
        headerName: "First Name",
        width: 200,
        editable: true,
        renderCell: (params) => {
          let value = params?.value;
          let originalString = value;
          if (originalString?.includes("‰")) {
            const replacedString = originalString?.replace(/‰/g, "¨");
            const decodedString = decodeURIComponent(escape(replacedString));
            value = decodedString;
          }
          return (
            <div>
              {value?.includes("é") ||
                value?.includes("è") ||
                value?.includes("É") ||
                value?.includes("ë") ||
                value?.includes("ç") ||
                value?.includes("î") ||
                value?.includes(" ") ||
                value?.includes("ü") ||
                value?.includes("ö") ||
                value?.includes("Ë") ||
                value?.includes("ô") ||
                value?.includes("ä") ||
                value?.includes("ï")
                ? value
                : decodeURIComponent(escape(value))}
            </div>
          );
        },
      },

      {
        field: "lastname",
        headerName: "Last Name",
        width: 200,
        editable: true,
        groupable: false,
        renderCell: (params) => {
          let value = params?.value;
          let originalString = value;
          if (originalString?.includes("‰")) {
            const replacedString = originalString?.replace(/‰/g, "¨");
            const decodedString = decodeURIComponent(escape(replacedString));
            value = decodedString;
          }
          return (
            <div>
              {value?.includes("é") ||
                value?.includes("è") ||
                value?.includes("É") ||
                value?.includes("ë") ||
                value?.includes("ç") ||
                value?.includes("î") ||
                value?.includes(" ") ||
                value?.includes("ü") ||
                value?.includes("ö") ||
                value?.includes("Ë") ||
                value?.includes("ô") ||
                value?.includes("ä") ||
                value?.includes("ï")
                ? value
                : decodeURIComponent(escape(value))}
            </div>
          );
        },
      },
      {
        field: "email",
        headerName: "Email",
        width: 200,
        editable: true,
        groupable: false,
      },
      // {
      //   field: "binary_details",
      //   headerName: "Binary Details",
      //   width: 150,
      //   renderCell: (params) => (
      //     <Button
      //       variant="contained"
      //       onClick={() => handleBinaryDetailsClick(params.row)}
      //     >
      //       Binary Details
      //     </Button>
      //   ),
      // },
      {
        field: "sponsor_name",
        headerName: "Sponsor Name",
        width: 200,
        editable: true,
        groupable: false,
      },
      {
        field: "sponsor_email",
        headerName: "Sponsor Email",
        width: 200,
        editable: true,
        groupable: false,
      },
      {
        field: "createdat",
        headerName: "CreatedAt",
        width: 200,
        editable: true,
        groupable: false,
      },
      {
        field: "mobile",
        headerName: "Mobile ",
        width: 200,
        editable: true,
        groupable: false,
      },
      // {
      //   field: "bank_account_title",
      //   headerName: "Bank Account Title",
      //   width: 200,
      //   editable: true,
      //   groupable: false,
      //   valueGetter: (params) => {
      //     const { value } = params;
      //     return value ? value : "N/A";
      //   },
      // },
      // {
      //   field: "bank_account_iban",
      //   headerName: "Bank Account IBAN",
      //   width: 200,
      //   editable: true,
      //   groupable: false,
      //   valueGetter: (params) => {
      //     const { value } = params;
      //     return value ? value : "N/A";
      //   },
      // },
      // {
      //   field: "bank_account_bic",
      //   headerName: "Bank Account BIC",
      //   width: 200,
      //   valueGetter: (params) => {
      //     const { value } = params;
      //     return value ? value : "N/A";
      //   },
      // },
      // {
      //   field: "bank_account_country",
      //   headerName: "Bank Account Country",
      //   width: 200,
      //   valueGetter: (params) => {
      //     const { value } = params;
      //     return value ? value : "N/A";
      //   },
      // },
      // {
      //   field: "wallet_address",
      //   headerName: "Address",
      //   width: 200,
      //   valueGetter: (params) => {
      //     const { value } = params;
      //     return value ? value : "N/A";
      //   },
      // },
      {
        field: "subscription_status",
        headerName: "Subscription Status",
        width: 200,
      },
      {
        field: "login_status",
        headerName: " Login Status",
        width: 200,
      },
      // {
      //   field: "rank_name",
      //   headerName: "Current Rank",
      //   width: 200,
      // },
      // {
      //   field: "life_time_rank_name",
      //   headerName: "Life Time Rank",
      //   width: 200,
      // },
    ];
  }

  // const handleDelete = (id) => {
  //     let params = { id: id }
  //     deletenews(params, (response) => {
  //         if (response?.data?.status === "error") {
  //             setalertData({
  //                 show: true,
  //                 message: response?.data?.message,
  //                 variant: "error"
  //             })
  //         }
  //         else if (response?.data?.status === "success") {
  //             setalertData({
  //                 show: true,
  //                 message: response?.data?.message,
  //                 variant: "success"
  //             })
  //         }
  //         else {
  //             setalertData({
  //                 show: true,
  //                 message: 'Something went wrong please try again later',
  //                 variant: "error"
  //             })
  //         }
  //     }, (error) => {
  //         console.log(error?.response?.data);
  //     });
  //     setManageAllNews((data) => data.filter((row) => row.id !== id));
  // }ApprovekycApi

  // const gridDesign = {
  //     '& .MuiDataGrid-toolbarContainer': {
  //         '& .MuiButton-text': {
  //             fontSize: '13px !important',
  //             // color: '#000',
  //             color: theme.palette.mode === 'dark' ? '#fff' : '#000',
  //         },
  //         '& .MuiBadge-badge': {
  //             backgroundColor: '#074682',
  //         },
  //         '& .MuiInput-root': {
  //             borderRadius: 2,
  //             paddingLeft: 2,
  //             overflow: 'hidden',
  //         },

  //     }
  // }
  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
        // color: '#074682',
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };
  //

  const handleOpen = (id) => {

    const rowToEdit = getUsersList.find((row) => row.id === id);
    let params = {
      userid: rowToEdit.id,
    };
    singleUserApi(params, (response) => {
      if (
        response?.data?.data[0].bank_account_title === null &&
        response?.data?.data[0].wallet_address === null &&
        response?.data?.data[0].outside_bank_account_title === null
      ) {
        setUpdatePayoutInformationOpen(true);
      } else {
        setCurrentPayoutDetails(true);
      }
    });
    setRowData(rowToEdit);
    setOpen(false);
  };

  const handleSyncOldMessages = (data) => {
    window.open(`https://novalya.chargebee.com/d/customers/${data.customerid}`,'_blank');
    setOpen(false);
  }

  
  



  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          title={t("pages.title.userlist")}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {/* {isLoading ? (
              <Div
              sx={{
                mt:"20%",
                ml:"45%",
                mb: "20%"
              }}
            >
              <CircularProgress />
            </Div>
          ):( */}

          <Box sx={{ height: 500, width: 1 }}>
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={getUsersList}
              getRowId={(row) => row.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              loading={isLoading}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>
              Manage Account Of: {dialoguserdata?.row?.username}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {t("pages.title.dialogcontent")}
              </DialogContentText>
            </DialogContent>
            <Grid
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                paddingBottom: 1,
                textAlign: "center",
              }}
              spacing={1}
              container
            >
              <Grid item sm={6} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={DialogloginClick}
                >
                  {t("pages.title.login")}
                </Button>
              </Grid>

              {loginUserData.usertype !== "reseller" &&
                !loginUserData.parent_id && (
                  <Grid item sm={12} md={6} lg={6}>
                    <Button
                      sx={{ width: "100%" }}
                      variant="contained"
                      onClick={() =>
                        navigate("/update-user-balance", {
                          state: {
                            username: dialoguserdata?.row?.username,
                            id: dialoguserdata?.row?.id,
                            current_balance:
                              dialoguserdata?.row?.current_balance,
                          },
                        })
                      }
                    >
                      Update Balance
                    </Button>
                  </Grid>
                )}

              {loginUserData.usertype !== "reseller" &&
                !loginUserData.parent_id && (
                  <Grid item sm={6} md={6} lg={6}>
                    <Button
                      sx={{ width: "100%" }}
                      variant="contained"
                      onClick={() =>
                        navigate("/manage-user-unilevel", {
                          state: { user_id: dialoguserdata?.row?.id },
                        })
                      }
                    >
                      Level Bonus
                    </Button>
                  </Grid>
                )}

              <Grid item sm={12} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() =>
                    navigate("/manage-column", {
                      state: {
                        id: dialoguserdata?.row?.id,
                        birthday_status: dialoguserdata?.row?.birthday_status,
                        connect_status: dialoguserdata?.row?.connect_status,
                        crm_status: dialoguserdata?.row?.crm_status,
                        unfollow_status: dialoguserdata?.row?.unfollow_status,
                      },
                    })
                  }
                >
                  {t("pages.title.manage_columns")}
                </Button>
              </Grid>

              {/* <Grid item sm={6} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={BlockUserdata}
                >
                  {dialoguserdata?.row?.login_status === "Block"
                    ? "Unblock"
                    : "Block"}
                </Button>
              </Grid> */}
              {/* <Grid item sm={6} md={4} lg={4}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() =>
                    navigate("/binary-tree", {
                      state: { randomcode: dialoguserdata?.row?.randomcode },
                    })
                  }
                >
                  {t("pages.title.view_Tree")}
                </Button>
              </Grid> */}
            </Grid>

            <Grid
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                paddingBottom: 1,
                textAlign: "center",
              }}
              spacing={1}
              container
            >
              {/* <Grid item sm={12} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() =>
                    navigate("/affiliate-list-details", {
                      state: {
                        username: dialoguserdata?.row?.username,
                        id: dialoguserdata?.row?.id,
                      },
                    })
                  }
                >
                  {t("pages.title.AffiliateListDetails")}
                </Button>
              </Grid> */}
              {/* <Grid item sm={12} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() =>
                    navigate("/add-deduct-binarypoints", {
                      state: {
                        username: dialoguserdata?.row?.username,
                        id: dialoguserdata?.row?.id,
                      },
                    })
                  }
                >
                  Manage Points
                </Button>
              </Grid> */}
            </Grid>

            {/* <Grid
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                paddingBottom: 1,
                textAlign: "center",
              }}
              spacing={1}
              container
            >
              <Grid item sm={12} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() =>
                    navigate("/monthly-report", {
                      state: { id: dialoguserdata?.row?.id },
                    })
                  }
                >
                  {t("pages.title.monthly_report")}
                </Button>
              </Grid>

              <Grid item sm={6} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() =>
                    navigate("/manage-user-unilevel", {
                      state: { user_id: dialoguserdata?.row?.id },
                    })}> Level Bonus
                </Button>
              </Grid >

              <Grid item sm={12} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() =>
                    navigate("/manage-column", {
                      state: {
                        id: dialoguserdata?.row?.id,
                        birthday_status: dialoguserdata?.row?.birthday_status,
                        connect_status: dialoguserdata?.row?.connect_status,
                        crm_status: dialoguserdata?.row?.crm_status,
                        unfollow_status: dialoguserdata?.row?.unfollow_status,
                      },
                    })
                  }
                >
                  {t("pages.title.manage_columns")
              </Button>
              </Grid >
            </Grid> */}

            <Grid
              spacing={1}
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                paddingBottom: 1,
                textAlign: "center",
              }}
              container
            >
              <Grid item sm={12} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() =>
                    navigate("/update-user-password", {
                      state: {
                        username: dialoguserdata?.row?.username,
                        id: dialoguserdata?.row?.id,
                      },
                    })
                  }
                >
                  Update Password
                </Button>
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() =>
                    navigate("/update-user-personal-info", {
                      state: { user_id: dialoguserdata?.row?.id },
                    })
                  }
                >
                  Personal Info
                </Button>
              </Grid>
            </Grid>

            <Grid
              spacing={1}
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                paddingBottom: 1,
                textAlign: "center",
              }}
              container
            >
              <Grid item sm={12} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() =>
                    navigate("/update-user-plan-limits", {
                      state: { user_id: dialoguserdata?.row?.id },
                    })
                  }
                >
                  Plan Limits
                </Button>
              </Grid>

              {loginUserData.usertype !== "reseller" &&
                !loginUserData.parent_id && (
                  <Grid item sm={12} md={6} lg={6}>
                    <Button
                      sx={{ width: "100%" }}
                      variant="contained"
                      onClick={() =>
                        navigate("/change-sponsor", {
                          state: { user_id: dialoguserdata?.row?.id },
                        })
                      }
                    >
                      Change Sponsor
                    </Button>
                  </Grid>
                )}
            </Grid>

            <Grid
              spacing={1}
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                paddingBottom: 1,
                textAlign: "center",
              }}
              container
            >
              <Grid item sm={6} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() =>
                    BlockUserdata()
                  }
                >
                {dialoguserdata?.row?.login_status === "Unblock"? "Block User":"Unblock User"}
                </Button>
              </Grid>

              <Grid item sm={6} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={()=>toggleApi()}
                  disabled={chatBtnDisable}
                >
                
                  {isChatEnable?"Disable chat":"Enable chat"}
                </Button>
              </Grid>

              <Grid item sm={6} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() => {
                    handleOpen(dialoguserdata?.row?.id);
                  }}
                >
                  Update Payout Detail
                </Button>
              </Grid>

              <Grid item sm={6} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" ,backgroundColor:"orange"}}
                  variant="contained"
                  onClick={() => {
                    handleSyncOldMessages(dialoguserdata?.row);
                  }}
                >
                  ChargeBee
                </Button>
              </Grid>

              {/* <Grid item sm={12} md={12} lg={12}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() =>
                    navigate("/change-sponsor", {
                      state: { user_id: dialoguserdata?.row?.id },
                    })
                  }
                >
                  Change Sponsor
                </Button>
              </Grid> */}
            </Grid>

            {/* <Grid sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 1, textAlign: 'center' }} spacing={1} container>
                            
                        </Grid> */}

            <DialogActions>
              {/* <Button onClick={() => setOpen(false)}>Disagree</Button> */}
              <Button
                onClick={() => {
                  setOpen(false);
                  GetUsersList();
                }}
              >
                {t("pages.title.close")}
              </Button>
            </DialogActions>
          </Dialog>
          {/* )} */}

          {/* start model for binary details */}
          <Dialog
            open={binaryDetailsOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setBinaryDetailsOpen(false)}
            aria-describedby="alert-dialog-slide-description"
          >
            {binaryLoader ? (
              <Div
                sx={{
                  display: "flex",
                  minWidth: 0,
                  alignItems: "center",
                  alignContent: "center",
                  height: 300,
                  width: 300,
                }}
              >
                <CircularProgress sx={{ m: "-40px auto 0" }} />
              </Div>
            ) : (
              <>
                <DialogTitle sx={{ marginBottom: -1 }}>
                  Binary details of {singleRowData?.username}
                </DialogTitle>

                <List
                  sx={{
                    width: 500,
                    maxWidth: 360,
                    mb: 2,
                    bgcolor: "background.paper",
                  }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <GroupIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{
                        borderStyle: "solid",
                        borderColor: "red",
                        borderWidth: 4,
                        paddingLeft: 1,
                      }}
                      primary="Left Right Active Personal Members"
                      secondary={
                        binaryDetails?.activeReferralMembers
                          ?.leftPersonalActiveMembers +
                        "/" +
                        binaryDetails?.activeReferralMembers
                          ?.rightPersonalActiveMembers
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <GroupIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{
                        borderStyle: "solid",
                        borderColor: "red",
                        borderWidth: 4,
                        paddingLeft: 1,
                      }}
                      primary="Left Right Binary Team Points"
                      secondary={
                        binaryDetails?.activeBinaryPoints
                          ?.leftOrganizationPoints +
                        "/" +
                        binaryDetails?.activeBinaryPoints
                          ?.rightOrganizationPoints
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <GroupIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Left Right Personal Referral Points"
                      secondary={
                        binaryDetails?.activeReferralPoints
                          ?.leftReferralPoints +
                        "/" +
                        binaryDetails?.activeReferralPoints?.rightReferralPoints
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <GroupIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Left Right Binary Team Members"
                      secondary={
                        binaryDetails?.activeBinaryMembers
                          ?.leftOrganizationMembers +
                        "/" +
                        binaryDetails?.activeBinaryMembers
                          ?.rightOrganizationMembers
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <MilitaryTechIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Current Payout Rank"
                      secondary={singleRowData?.rank_name}
                    />
                  </ListItem>
                  {/* <Divider variant="inset" component="li" />
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <GroupIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Life Time Payout Rank" secondary={singleRowData?.life_time_rank_name} />
                                        </ListItem> */}
                  <Divider variant="inset" component="li" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <MilitaryTechIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Current NovaFree Rank"
                      secondary={singleRowData?.novafree_rank_name}
                    />
                  </ListItem>
                </List>

                <DialogActions sx={{ marginTop: -5 }}>
                  <Button onClick={() => setBinaryDetailsOpen(false)}>
                    {t("pages.title.close")}
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>
          {/* end model for binary details */}
        </JumboDemoCard>
      </Grid>

      <Modals
        open={openModal}
        setOpen={setOpenModal}
        rowData={rowData}
        currentPayoutDetails={currentPayoutDetails}
        setCurrentPayoutDetails={setCurrentPayoutDetails}
        updatepayoutinformationopen={updatepayoutinformationopen}
        setUpdatePayoutInformationOpen={setUpdatePayoutInformationOpen}
      />
    </Grid>
  );
};

export default Userlisttable;